<template>
	<div class="trialreport">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾体验报告"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		right-arrow
		right-text="分享"
		@click-right="show=true"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 客户信息 -->
		<div style="height: 14px;"></div>
		<div class="customer">
			<div class="customer__title">
				客户信息
			</div>
			<div class="customer__number">
				{{info.number}}
			</div>
			<div class="customer__row">
				<div class="customer__row__title">{{info.name}}</div>
				<img v-if="info.gender == 1" style="margin-left: 10px;" src="@/assets/common/icon_man.png">
				<img v-if="info.gender == 0" style="margin-left: 10px;" src="@/assets/common/icon_woman.png">
			</div>
			<div class="customer__row">
				<div class="customer__row__title">联系方式：</div>
				<div class="customer__row__con">{{info.mobile}}</div>
			</div>
			<div class="customer__row">
				<div class="customer__row__title">试驾车型：</div>
				<div class="customer__row__con">{{info.model_name}} {{info.collocation_name}}</div>
			</div>
			<div class="customer__row2" style="margin-top: 10px;">
				<div class="customer__row2__title">试驾时间：</div>
				<div class="customer__row2__con"><span>{{getDateTime1(info.starttime,info.finishtime)[0]}}</span><br/><span>{{getDateTime1(info.starttime,info.finishtime)[1]}}</span></div>
			</div>
			<div class="customer__kdlk">
				<img src="@/assets/trial/icon_kdlk.png">
			</div>
		</div>
		
		<!-- 选项 -->
		<div class="customer_time">
			<div class="customer_time_title">
				<div class="time">
					试驾时长<img src="@/assets/drivroute/icon_chezi.png" mode="">
				</div><div @click="uploadedit" class="edit">
					编辑
				</div>
			</div>
			
			<div class="customer_time_concat">
				<div class="">
					{{form.cruise_duration}}<span>分钟</span>
				</div>
				
			</div>
		</div>
		
		<div class="customer_time">
			<div class="customer_time_title">
				<div class="time">
					试驾距离
					<img src="@/assets/drivroute/icon_lux.png" mode="">
				</div><div @click="uploadedit" class="edit">
					编辑
				</div>
			</div>
			
			<div class="customer_time_concat">
				<div class="">
					{{form.cruise_distance}}<span>公里</span>
				</div>
				
			</div>
		</div>

		<van-overlay style="display: flex;align-items: center;justify-content: center;" z-index="100000" :show="show" @click="show=false">
			<div class="wrapper" style="color: #fff;" @click.stop>
				<div class="abs" @click="show = false">关闭<img src="@/assets/trial/icon_guambi.png" alt=""></div>
				<div class="title">试乘试驾体验报告</div>
				<div class="concat">请顾客先扫描下方企业微信二维码添加好友</div>
				<div class="qrimg" :class="{'isborder':wechat_qr_image == '' || wechat_qr_image == null}">
					<div @click="gouser" v-if="wechat_qr_image == '' || wechat_qr_image == null" class="user_wxupload_upload_content">
						<span class="titleupload">尚未上传</span>
						<span class="titleupload">企业微信二维码</span>
						<span class="concatupload">点击设置</span>
					</div>
					<img v-else :src="wechat_qr_image" >
					</div>
				<div class="botton" @click="onshare">通过企业微信发送</div>
			</div>
		</van-overlay>

	</div>
</template>

<script>
import {getsignature} from '@/utils/utils'
export default {
	data() {
		return {
			wechat_qr_image:'',
			show:true,
			info: {
				number: '',
			},
			form: {
				cruise_duration: '',
				cruise_distance: '',
			}
		};
	},
	mounted() {
		if (this.$route.query.number) this.info.number = this.$route.query.number
		this.getTrialInfo()
		this.getreportInfo()
		this.getUserInfo()
	},
	methods: {
		onshare(){
			const url = localStorage.getItem('share')
			this.$wx.invoke(
						"shareAppMessage", {
							title: '试乘试驾体验报告', // 分享标题
							desc: '', // 分享描述
							link: url+'/scsj/scsjsjfk/?number=' + this.info.number, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
							imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
							enableIdTrans: 0, // 是否开启id转译，不填默认为0
						}, function(res) {
								if (res.err_msg == "shareAppMessage:ok") {
									this.$toast.success('分享成功')						//正确处理
								}else {
									getsignature()					//错误处理
								}
						}
					);
					this.show=false
		},
				// 用户详情
				async getUserInfo(){
			const {data: result} = await this.$http.post('/api/user/get_userinfo_1_5_1')
			if (result.code == 1) {
				this.wechat_qr_image = result.data.res.wechat_qr_image
			}
		},
		// 修改二维码
		gouser(){
			this.$router.push({
				path: `/useredit`
			})
		},
		getDateTime1(timestamp,timestamp2) {
			const date = new Date(timestamp * 1000)
			const date2 = new Date(timestamp2 * 1000)
			const Y = date.getFullYear() + '年'
			const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
			const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
			const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
			const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + '-'
			const h2 = (date2.getHours() < 10 ? '0' + date2.getHours() : date2.getHours()) + ':'
			const m2 = (date2.getMinutes() < 10 ? '0' + date2.getMinutes() : date2.getMinutes())
			return [Y + M + D, h + m + h2 + m2]
		},
		// 编辑
		uploadedit(){
			this.$router.replace({
				path: `/trial-data-super?number=${this.info.number}`
			})
		},
		// 试驾详情
		async getTrialInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.info.car_images = this.serverUrl(this.info.car_images)
			}
		},
		// 试驾详情
		async getreportInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_report_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.form.cruise_duration = result.data.res.cruise_duration
				this.form.cruise_distance = result.data.res.cruise_distance
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.isborder{
	border: 1px solid #000 !important;
}
.wrapper{
	width: 602px;
	height: 812px;
	position: relative;
	background: url(http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/tkbj.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	color: #191919;
	background-color: #000;
	
	padding: 48px;
	box-sizing: border-box;
	.botton{
		margin-top: 70px;
		width: 100%;
		height: 92px;
		background: #FA0037;
		line-height: 92px;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		
		color: #ECECEC;
	}
	.qrimg{
		
		.user_wxupload_upload_content{
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			align-items: center;
			width: 236px;
			height: 236px;
			padding: 60px 0 0 0;
			top: 0;
			left: 0;
			img{
				width: 48px;
				height: 48px;
			}
			.titleupload{
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				opacity: 0.4;
				font-weight: normal;
				color: #101010;
				margin-top: 10px;
				line-height: 28px;
			}
			.concatupload{
				font-size: 44px;
				margin-top: 40px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #101010;
				line-height: 40px;
			}
		}
		margin: 40px auto 0;
		width: 320px;
		height: 320px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #fff;
		&__btn{
			&__row1{
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #9B9B9B;
				line-height: 36px;
			}
			&__row2{
				margin-top: 10px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #fff;
				line-height: 36px;
			}
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
	.concat{
		text-align: center;
		padding: 20px 0;
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #919191;
		line-height: 28px;
	}
	.title{
		text-align: center;
		padding: 20px;
		font-size: 40px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #101010;
		line-height: 40px;
	}
	.abs{
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		display: flex;
		align-items: center;
		font-weight: normal;
		color: #818181;
		line-height: 32px;
		img{
			margin-left: 10px;
			width: 32px;
			height: 32px;
		}
	}
}
.wrapper{
	width: 602px;
	height: 812px;
	position: relative;
	background: url(http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/tkbj.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	color: #191919;
	background-color: #000;
	padding: 48px;
	box-sizing: border-box;
	.botton{
		margin-top: 70px;
		width: 100%;
		height: 92px;
		background: #FA0037;
		line-height: 92px;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #ECECEC;
	}
	.qrimg{
		margin: 40px auto 0;
		width: 320px;
		height: 320px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #fff;
		&__btn{
			&__row1{
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #9B9B9B;
				line-height: 36px;
			}
			&__row2{
				margin-top: 10px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #fff;
				line-height: 36px;
			}
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
	.concat{
		text-align: center;
		padding: 20px 0;
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #919191;
		line-height: 28px;
	}
	.title{
		text-align: center;
		padding: 20px;
		font-size: 40px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #101010;
		line-height: 40px;
	}
	.abs{
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		display: flex;
		align-items: center;
		font-weight: normal;
		color: #818181;
		line-height: 32px;
		img{
			margin-left: 10px;
			width: 32px;
			height: 32px;
		}
	}
}

.customer{
	margin: 0 auto;
	width: 686px;
	padding: 32px;
	background: #222222;
	box-sizing: border-box;
	border-radius: 24px;
	position: relative;
	&__title{
		margin-bottom: 40px;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #ECECEC;
		line-height: 40px;
	}
	&__row{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		height: 40px;
		&__title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
		}
		&__con{
			font-size: 28px;
			font-family: Regular;
			color: #FFFFFF;
		}
		img{
			margin-right: 20px;
			width: 32px;
			height: 32px;
		}
	}
	&__row2{
		display: flex;
		margin-bottom: 20px;
		height: 40px;
		&__title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
		}
		&__con{
			font-size: 28px;
			font-family: Regular;
			color: #FFFFFF;
			line-height: 40px;
		}
		img{
			margin-right: 20px;
			width: 32px;
			height: 32px;
		}
	}
	&__number{
		margin-bottom: 20px;
		font-size: 24px;
		font-family: Regular;
		color: #ECECEC;
		line-height: 24px;
	}
	&__car{
		display: inline-block;
		padding: 0 10px;
		line-height: 48px;
		background: #F3C846;
		border-radius: 8px;
		font-size: 28px;
		font-family: HYQiHeiY3-55;
		color: #333333;
	}
	&__kdlk{
		position: absolute;
		top: 36px;
		right: 32px;
		width: 96px;
		height: 36px;
		img{
			width: 100%;
			height: 100%;
		}
	}
}
.customer_time{
	margin: 32px 24px 0;
	height: 306px;
	padding: 0 34px;
	background: #222222;
	.customer_time_title{
		padding-left: 240px;
		align-items: center;
		display: flex;
		justify-content: space-between;
		height: 92px;
		border-bottom: 1px solid #333333;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #ECECEC;
		.time{
			display: flex;
			align-items: center;
			img{
				width: 64px;
				height: 64px;
				margin-left: 20px;
			}
		}
		.edit{
			width: 118px;
			height: 54px;
			text-align: center;
			line-height: 54px;
			font-size: 30px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #F3C846;
			border: 1px solid #979797;
		}
	}
	.customer_time_concat{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 210px;
		font-size: 80px;
		font-family: Regular;
		font-weight: 400;
		color: #FFD974;
		span{
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
		}
	}
}
.opts{
	margin: 50px auto 0;
	width: 686px;
	text-align: center;
	&__title{
		margin-bottom: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #ECECEC;
		img{
			margin-left: 12px;
		}
	}
	.opt__box{
		display: flex;
		justify-content: space-around;
		.opt{
			width: 280px;
			&__con{
				display: flex;
				justify-content: center;
				div:nth-child(1){
					font-size: 52px;
					font-family: Regular;
					color: #E8B01E;
					line-height: 62px;
				}
				div:nth-child(2){
					margin-left: 10px;
					font-size: 28px;
					font-family: HYQiHeiY3-45;
					color: #ECECEC;
					line-height: 62px;
				}
			}
			&__init1{
				margin: 10px auto 0;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				color: #ECECEC;
				line-height: 28px;
			}
			&__init2{
				margin: 40px auto 0;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				color: #F5D98F;
				line-height: 28px;
			}
		}
	}
}
.opts__bg{
	padding: 50px 0 70px;
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_report.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.trial__close__box{
	padding: 20px 32px;
}
.trial__close__btn{
	width: 100%;
	line-height: 88px;
	background: #fa0037;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #F4F4F4;
	text-align: center;
}
</style>
